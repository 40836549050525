<template>
  <section v-if="agent.type === 'Admin'">
        <b-button
          :label="userId ? '' : 'Assign'"
          type="is-danger"
          icon-left="tag"
          @click.stop="isComponentModalActive = true"
        />

        <b-modal
          v-model="isComponentModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="false"
          aria-role="dialog"
          aria-label="Lead Assigner Modal"
          aria-modal
        >
          <template #default="props">
            <div class="modal-card" style="width: auto">
              <header class="modal-card-head">
                <span class="icon mr-4">
                  <i class="fas fa-2x fa-users-cog"></i>
                </span>
                <p class="title is-4">Assign Agent to Lead</p>
              </header>
              <section
                class="modal-card-body"
              >
                <div
                  class="block"
                  v-for="(agentType, index) in Object.keys(groupAgentByType)"
                  :key="index"
                >
                  <h5 class="title is-5">{{ agentType }}</h5>
                  <b-button
                    v-for="agent in groupAgentByType[agentType]"
                    :type="
                      !user.agentId || userId ? 'is-light is-info'
                      : agent.id === user.agentId
                      ? 'is-light is-success'
                      : 'is-light is-info'
                    "
                    :icon-left="
                    !user.agentId || userId ? ''
                      : agent.id === user.agentId
                      ? 'check'
                      : ''
                    "
                    :key="agent.id"
                    @click.stop="() => assignLead(agent.id)"
                    expanded
                    >{{ agent.name }}</b-button
                  >
                </div>
              </section>
              <footer class="modal-card-foot">
                <b-button label="Close" expanded @click="props.close" />
              </footer>
            </div>
          </template>
        </b-modal>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { getAgentById } from '@/common';

export default {
  name: 'LeadAssigner',
  data() {
    return {
      isComponentModalActive: false,
    };
  },
  props: {
    userId: {
      type: String,
    },
  },
  methods: {
    ...mapActions(['addAgentToLead', 'setUserId', 'updateUserAgent']),
    getAgentById,
    createSnackbar(message, success) {
      return this.$buefy.toast.open({
        message,
        type: success ? 'is-success' : 'is-warning',
        duration: 3500,
        container: 'section',
        position: 'is-top',
      });
    },
    async assignLead(e) {
      if (this.agent.type === 'Admin') {
        if (this.userId && this.userId !== this.user.id) {
          this.setUserId(this.userId);
        }
        try {
          await this.addAgentToLead(e);
        } catch (error) {
          return this.createSnackbar(error.message);
        }
        if (this.userId) {
          this.updateUserAgent({ userId: this.userId, agentId: e });
        }
        return this.createSnackbar('Assigned to Agent', true);
      }
      return this.createSnackbar('Not Permitted', false);
    },
  },
  computed: {
    ...mapState({
      user: ({ primary: { user } }) => user,
      agent: ({ agentData: { agent } }) => agent,
      allAgents: ({ agentData: { allAgents } }) => allAgents,
    }),
    groupAgentByType() {
      const allEmployedAgents = this.allAgents.filter(({ employed }) => employed);
      return allEmployedAgents.reduce((acc, agent) => {
        if (agent.type === 'Admin' || agent.type === 'Calling') {
          if (acc[agent.type]) {
            acc[agent.type].push(agent);
          } else {
            acc[agent.type] = [agent];
          }
        }
        return acc;
      }, {});
    },
  },
};
</script>
