<template>
  <div class="mt-6">
    <Filters/>
    <hr />
    <LeadTable/>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import Filters from './Filters.vue';
import LeadTable from './LeadTable.vue';

export default {
  name: 'LeadList',
  async mounted() {
    try {
      await this.getLeads();
    } catch (error) {
      console.error(error);
      this.$router.push({ name: 'Login' });
    } finally {
      // this.isLoading = false;
    }
    return null;
  },
  components: {
    Filters,
    LeadTable,
  },
  methods: {
    ...mapActions([
      'getLeads',
    ]),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
table {
  margin-top: 2.5em;
  cursor: pointer;
}

.table tbody tr:hover {
  // background-color: #e7eaee !important;
  background-color: #d8e2dc !important;
}

// .table tbody tr td.limit-width {
//   width: 10em;
// }

.table tbody tr:hover .transition-on-hover {
  background-color: #effaf3;
  color: #257942;
  border-color: #dbdbdb;
}

.table th,
.table td {
  padding-bottom: 0.65em;
}

.transition-on-hover {
  transition-property: background-color, color, border-color;
  transition-duration: 1s;
  background-color: transparent;
  color: transparent;
  border-color: transparent;
}

.float-right {
  float: right;
}

.mx-1 {
  margin: auto 0.5em;
}

.mt-2 {
  margin-top: 1em;
}

.columns.mt--8 {
  margin-top: -4em;
}

.columns {
  margin-top: 2em;
}
</style>
