<template>
    <LeadList />
</template>

<script>
import LeadList from '@/components/LeadList/LeadList.vue';

export default {
  components: {
    LeadList,
  },
};
</script>
