<template>
  <div>
  <div class="columns is-centered">
      <div class="column is-9" id="lead-filters">
        <b-button
        v-if="agent.type === 'Admin'"
        @click="getAllLeads">All</b-button>
        <b-button
          v-if="agent.type === 'Admin'"
          class="has-background-primary has-text-white"
          @click="filterBySelf"
        >Self</b-button>
        <span
        v-for="status in filterStatuses"
        :key="status.name"
        >
        <b-button
          v-if="(status.type === agent.type) || !status.type"
          v-bind:class="getClassByStatus(status.name)"
          @click="() => filterByStatus(status.name)"
        >
          {{ status.name }}
        </b-button>
        </span>
      </div>
    </div>
      <div class="columns is-centered">
        <div class="column is-9">
          <nav class="level">
        <!-- Left side -->
        <div class="level-left">
          <div class="level-item">
            <span v-bind:class="'tag is-medium ' + getClassByStatus(getActiveFilter)">
              {{ getActiveFilter }}</span>
            <h3 class="is-size-5 p-3"><b>Page:</b> {{ page }}</h3>
          </div>
        </div>

        <!-- Right side -->
        <div class="level-right">
          <p class="level-item">
            <b-field>
              <b-switch
                :value="sortByCreatedAt"
                @input="changeSortBy"
                passive-type="is-dark"
                type="is-warning"
              >{{ sortByCreatedAt ? "CREATED" : "UPDATED" }}</b-switch>
            </b-field>
          </p>
        </div>
      </nav>
        </div>
      </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import {
  getClassByStatus,
} from '@/common';

export default {
  name: 'Filters',
  data: () => ({
    filterStatuses: [
      { name: 'Open', type: '' },
      { name: 'Sale', type: '' },
      { name: 'Pending Payment', type: '' },
      { name: 'Callback', type: '' },
      { name: 'Dispute', type: '' },
      { name: 'Answering Machine', type: 'Admin' },
      { name: 'Refund', type: 'Admin' },
      { name: 'Duplicate', type: 'Admin' },
      { name: 'Not Interested', type: 'Admin' },
      { name: 'Not Reachable', type: 'Admin' },
    ],
  }),
  methods: {
    ...mapActions([
      'setFilter',
      'setFilterStatus',
      'setSortByCreatedAt',
      'updatePage',
      'getLeads',
      'setIsLoading',
    ]),
    getClassByStatus,
    async getAllLeads() {
      this.setIsLoading(true);
      this.setFilter(undefined);
      this.updatePage(1);
      try {
        await this.getLeads();
      } catch (error) {
        console.error(error);
        this.$router.push({ name: 'Home' });
      } finally {
        this.setIsLoading(false);
      }
    },
    async filterBySelf() {
      this.setFilter('self');
      this.setIsLoading(true);
      try {
        await this.getLeads();
      } catch (error) {
        console.error(error);
        this.$router.push({ name: 'Home' });
      } finally {
        this.setIsLoading(false);
      }
    },
    async filterByStatus(status) {
      this.setFilter('status');
      this.setFilterStatus(status);
      this.setIsLoading(true);
      try {
        await this.getLeads();
      } catch (error) {
        console.error(error);
        this.$router.push({ name: 'Home' });
      } finally {
        this.setIsLoading(false);
      }
    },
    async changeSortBy(isCreatedAt) {
      this.setIsLoading(true);
      this.setSortByCreatedAt(isCreatedAt);
      try {
        await this.getLeads();
      } catch (error) {
        console.error(error);
        this.$router.push({ name: 'Home' });
      } finally {
        this.setIsLoading(false);
      }
    },
  },
  computed: {
    ...mapState({
      isLoading: ({ primary: { isLoading } }) => isLoading,
      agent: ({ agentData: { agent } }) => agent,
      filter: ({ primary: { filter } }) => filter,
      filterStatus: ({ primary: { filterStatus } }) => filterStatus,
      page: ({ primary: { page } }) => page,
      sortByCreatedAt: ({ primary: { sortByCreatedAt } }) => sortByCreatedAt,
    }),
    getActiveFilter() {
      if (this.filter === 'status') {
        return this.filterStatus;
      }
      if (this.filter) {
        return this.filter;
      }
      return 'All';
    },
  },
};
</script>
