<template>
  <div>
  <table v-if="!isLoading" class="table is-fullwidth is-hoverable">
      <thead>
        <tr>
          <th v-if="filter === 'status' && filterStatus === 'Callback'">
            Scheduled Callback
          </th>
          <th v-else>Date</th>
          <th>Assignee</th>
          <th>Name</th>
          <th>Phone</th>
          <th>Email</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-bind:class="'is-clickable ' + getClassByStatus(lead.status)"
          v-for="lead in leads"
          :key="lead.id"
          @click.self="navigateToCandidate(lead.id)"
        >
          <td
          @click="navigateToCandidate(lead.id)"
          v-if="filter === 'status' && filterStatus === 'Callback'">
            {{ getHumanDate(lead.callback) }}
          </td>
          <td v-else
            @click="navigateToCandidate(lead.id)"
          >{{ getHumanDate(lead.createdAt) }}</td>
          <td
            @click="navigateToCandidate(lead.id)"
          >
            <span v-if="lead.agentId">
              <b-tag class="mx-1" type="is-primary">
                {{ lead.agentId && getAgentById(lead.agentId) }}
                </b-tag>
            </span>
          </td>
          <td
          @click="navigateToCandidate(lead.id)"
          ><b>{{ `${lead.title} ${lead.firstName} ${lead.lastName} ` }}</b></td>
          <td
          @click="copyToClipboard(lead.phoneNumber)"
          >
          <b-tooltip
          position="is-bottom" label="Copy Phone Number">
            {{ lead.phoneNumber }}
          </b-tooltip>
          </td>
          <td
            @click="navigateToCandidate(lead.id)"
          >{{ lead.email }}</td>
          <td>
            <span>
              <b-tooltip position="is-bottom" label="Mark as Duplicate">
                <b-button
                  icon-pack="fa"
                  icon-left="clone"
                  class="mx-2 transition-on-hover"
                  v-on:click.stop="() => markAsDuplicate(lead.id)"
                ></b-button>
              </b-tooltip>
              <LeadAssigner :userId="lead.id" />
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <Loading v-else />
    <div>
      <b>Page: {{ page }}</b>
    </div>
    <div class="is-flex-direction-row">
      <b-button
        class="is-justify-content-flex-start"
        @click="decrementPage"
        :disabled="page === 1"
        >Prev</b-button
      >
      <b-button
        class="is-justify-content-flex-start"
        @click="
          () => {
            updatePage(1);
            getLeads();
          }
        "
        >1</b-button
      >
      <b-button
        class="is-justify-content-flex-start"
        @click="
          () => {
            updatePage(5);
            getLeads();
          }
        "
        >5</b-button
      >
      <b-button
        class="is-justify-content-flex-start"
        @click="
          () => {
            updatePage(10);
            getLeads();
          }
        "
        >10</b-button
      >
      <b-button
        class="is-justify-content-flex-start"
        @click="
          () => {
            updatePage(20);
            getLeads();
          }
        "
        >20</b-button
      >
      <b-button
        class="is-justify-content-flex-start"
        @click="
          () => {
            updatePage(50);
            getLeads();
          }
        "
        >50</b-button
      >
      <b-button
        class="is-justify-content-flex-start"
        @click="
          () => {
            updatePage(75);
            getLeads();
          }
        "
        >75</b-button
      >
      <b-button class="is-justify-content-flex-end" @click="incrementPage"
        >Next</b-button
      >
      <div class="columns is-centered">
        <div class="column is-4">
          <b-field>
            <b-numberinput
              :value="page"
              @input="updateAndQueryLeads"
              min="1"
            ></b-numberinput>
          </b-field>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import Loading from '@/components/Loading.vue';
import LeadAssigner from '@/components/EditForms/LeadDetails/Personal/LeadAssigner.vue';
import {
  navigateToCandidate,
  getClassByStatus,
  createSnackbar,
  getHumanDate,
  getAgentById,
} from '@/common';

export default {
  name: 'LeadTable',
  components: {
    Loading,
    LeadAssigner,
  },
  methods: {
    ...mapActions([
      'setUserId',
      'updateCandidateDetails',
      'setLeadDuplicate',
      'updatePage',
      'setIsLoading',
      'getLeads',
    ]),
    navigateToCandidate,
    getClassByStatus,
    createSnackbar,
    getHumanDate,
    getAgentById,
    async copyToClipboard(phoneNumber) {
      try {
        await navigator.clipboard.writeText(`+44${phoneNumber}`);
        return this.createSnackbar(`+44${phoneNumber} copied to clipboard`, true);
      } catch (error) {
        return this.createSnackbar('LUnable to copy phone number', true);
      }
    },
    async markAsDuplicate(userId) {
      this.setUserId(userId);
      try {
        await this.updateCandidateDetails({ status: 'Duplicate' });
        this.setLeadDuplicate();
        return this.createSnackbar('Lead has been marked as Duplicate', true, '#lead-table');
      } catch (error) {
        console.error(error);
        window.scrollTo(0, 0);
        return this.createSnackbar(error.message, false, '#lead-table');
      }
    },
    async decrementPage() {
      this.updatePage('DECREMENT');
      this.setIsLoading(true);
      try {
        await this.getLeads();
      } catch (error) {
        this.$router.push({ name: 'Home' });
        throw error;
      } finally {
        this.setIsLoading(false);
      }
    },
    async incrementPage() {
      this.updatePage('INCREMENT');
      this.setIsLoading(true);
      try {
        await this.getLeads();
      } catch (error) {
        this.$router.push({ name: 'Home' });
        throw error;
      } finally {
        this.setIsLoading(false);
      }
    },
    updateAndQueryLeads(page) {
      this.updatePage(page);
      this.getLeads();
    },
  },
  computed: {
    ...mapState({
      isLoading: ({ primary: { isLoading } }) => isLoading,
      filterStatus: ({ primary: { filterStatus } }) => filterStatus,
      filter: ({ primary: { filter } }) => filter,
      leads: ({ primary: { leads } }) => leads,
      page: ({ primary: { page } }) => page,
      allAgents: ({ agentData: { allAgents } }) => allAgents,
    }),
  },
};
</script>
